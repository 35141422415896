/* 
 * shared.css
 * stylesheet for homepage
 */


body:lang(en) {
  font-family: 'Open Sans Variable', sans-serif;
}

body:lang(zh-Hant) {
  font-family: 'Noto Sans HK Variable', sans-serif;
  letter-spacing: 2px;
}

.serif:lang(en) {
  font-family: 'Noto Serif HK Variable', sans-serif;
  /* letter-spacing: 1px; */
  font-weight: 900;
}

.serif:lang(zh-Hant) {
  font-family: 'Noto Serif HK Variable', sans-serif;
  letter-spacing: 1px;
  font-weight: 900;
}